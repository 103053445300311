import type { CardContentProps } from './CardContentA';

import * as React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { useHighlightHandlers } from '../../contexts/HighlightContext/Highlight';
import { Typography as MuiTypography } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import CardContent from '@mui/material/CardContent';

const Typography = React.forwardRef<HTMLInputElement, any>((props, ref) =>
  useHighlightHandlers(MuiTypography, { forwardedRef: ref, ...props })
);

const Button = React.forwardRef<HTMLButtonElement, any>((props, ref) =>
  useHighlightHandlers(MuiButton, { forwardedRef: ref, ...props })
);

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    border: theme.theme_component_card_content_border || 'none',
    borderTop: 'none',
    pointerEvents: 'none',
  },
})) as typeof CardContent;

const Description = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  height: '80px',
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
  display: 'inline-block',
  width: 'auto',
  height: 'auto',
  margin: 0,
  marginTop: theme.spacing(-3.5),
  padding: theme.spacing(0.5, 2),
  borderRadius: '100px',
  border: '1px solid #ebebeb',
  color: theme.theme_component_card_content_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_card_content_button_background || theme.palette.primary.main,
}));

const getText = (val: string, limit: number) => {
  if (val !== '' && val !== null && val !== undefined && val?.length > limit) {
    return `${val.slice(0, limit - 3)}...`;
  }
  return val;
};

// Todo: HUZ2ERA-1101 - Allow Typography props to be drilled (now we have them hardcoded in our jsx).
//       Had trouble extending our CardContentCProps...
export const CardContentC = (props: CardContentProps) => {
  const { description, limit, className, ...other } = props;
  const { t } = useTranslation('card_content_c');
  const body2Ref = React.useRef<HTMLInputElement | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <CardContentStyled className={className}>
      <Description ref={body2Ref} variant="body2" component="p" color="textPrimary" {...other}>
        {description && getText(description, limit || 85)}
      </Description>
      <ButtonStyled ref={buttonRef} variant="contained" disableElevation disableRipple disableFocusRipple size="small">
        {t('play_now', 'Play now')}
      </ButtonStyled>
    </CardContentStyled>
  );
};
