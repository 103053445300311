import * as React from 'react';
import styled from '@emotion/styled';
import { Button  } from '@mui/material';

const ButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.theme_component_feature_grid_a_card_button_color || theme.palette.primary.contrastText,
  background: theme.theme_component_feature_grid_a_card_button_background || theme.palette.primary.main,
  borderRadius: `${theme.theme_component_feature_grid_a_card_button_border_radius}px`,
  fontSize: theme.theme_component_feature_grid_a_card_button_font_size || '16px',
  fontWeight: theme.theme_component_feature_grid_a_card_button_font_weight,
  padding: "6px 16px",
  hover: {
    background: theme.theme_component_feature_grid_a_card_button_hover_background_color,
    color: theme.theme_component_feature_grid_a_card_button_active_background_color
  },
}));

export const CustomButton = ({ children, ...rest}: any) => {
  return (
    <ButtonStyled {...rest}>
      {children}
    </ButtonStyled>
  );
}